import './scss/index.scss';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { LandingPromoComponent } from '../LandingPromo.component/LandingPromo.component';
import { StepCardComponent } from '../StepCard.component/StepCard.component';
import { BeltComponent } from '../Belt.component/Belt.component';

import { FeatureComponent } from '../Feature.component/Feature.component';
import { LandingTextCards } from '../LandingTextCards.component/LandingTextCards.component';
import { SketchP5Component } from '../../graph/SketchP5Component';
import { FooterContainer } from '../../footer';
import { SimpleEventsListContainer } from '../../events';
import { LoginContainer, useTrackClick, Svg } from '../../common';
import { LandingVideoDemo } from '../LandingVideoDemo';
import { SuperTitleComponent } from '../../common/SuperTitle.component/SuperTitle.component';
import { SuperTextComponent } from '../../common/SuperText.component/SuperText.component';

import {
    FEATURES_DESCRIPTION,
    LOGOS,
    MENTORS,
    HOW_IT_WORKS,
    SCREENS,
    LANDING_EVENTS_LIST,
} from '../../constant';
import { InfoCard } from '../../common';

export const LandingEnComponent = (prop) => {
    const trackClick = useTrackClick();
    const scrollToClasses = useRef();
    const handleScrollClick = () => {
        scrollToClasses.current.scrollIntoView();
    };
    const [showLogin, setShowLogin] = useState();
    const handleLogin = () => {
        trackClick('click-login', 'course-tutorial');
        setShowLogin(true);
    };
    const infoBlocks = [
        {
            title: "Instant Course Creation",
            description: "15 minutes for the whole course with study guides, videos, quizzes, assignments takes: Upload your knowledge files and get all content automatically in 2 clicks.",
        },
        {
            title: "Personalized AI-assistant",
            description: "Adaptive content generate personalised lessons to each student's interests, helping them exceed traditional course expectations.",
        },
        {
            title: "Courses That Learn",
            description: "Courses improve automatically based on student interactions, new sources and discoveries.",
        }
    ];
    return (
        <div className='landing_dark '>
            <div className='first_promo'>
                <LandingPromoComponent
                    handleScrollClick={handleScrollClick}
                    trackClick={trackClick}
                    handleLogin={handleLogin}
                />

                <Link
                    to='/payments'
                    className='link-btn_primary ant-btn ant-btn-primary ant-btn-lg margin_right '
                >
                    <span>Start for free</span>
                </Link>
            </div>
            <div className=' wrapper_hero '>
            </div>



            <div className='wrapper_promo-section promo_red'>
                <LandingTextCards
                    title='Your content, your voice'
                    description='Convert your articles, pdf book or mp4 video into step-by-step courses, with AI videos, quizzes, practical tasks and assessments.'
                />
                <LandingVideoDemo taskId={281900} />
                <SimpleEventsListContainer eventsList={LANDING_EVENTS_LIST} openFirtsLesson={true} />

            </div>
            {/* <div className='wrapper_promo-section '>
                <div className=' events-list__cards '>
                    {infoBlocks.map((block, index) => (
                        <div className='info-block' key={index}>
                            <h2>{block.title}</h2>
                            <p>{block.description}</p>
                            <LazyLoadImage className="wrapper_content-section"
                                src={SCREENS[0].url}
                                alt={SCREENS[0].alt} />

                        </div>
                    ))}
                </div>
            </div> */}
            <div className='wrapper_promo-section  '>
                <LandingTextCards
                    title='How it works'
                />
                {HOW_IT_WORKS.map((step, i) => (
                    <StepCardComponent
                        key={`how-${i}`}
                        title={step.title}
                        description={step.description}
                        src={step.src}
                        index={i + 1}
                        color={step.color}
                        shear={step.shear}
                    />
                ))}

            </div>

            <div className='wrapper_promo-section promo_red'>
                <LandingTextCards
                    title='Launch your online-academy tonight'
                    description='Get your own whitelabeled website with AI and collect infinite insights about your students / employees / mentees'
                />

                <div className='cards_info wrapper_outer-section'>
                    <div className='step_wrapper_workspace'>
                        <div className='step_workspace'>
                            <LazyLoadImage
                                src={SCREENS[0].url}
                                alt={SCREENS[0].alt}
                            />
                        </div>
                    </div>

                    <div className='step_wrapper_workspace step_2'>
                        <div className='step_workspace'>
                            <LazyLoadImage
                                src={SCREENS[2].url}
                                alt={SCREENS[2].alt}
                            />
                        </div>
                    </div>


                </div>
                <div className='wrapper_quote'>
                    <div className='quote'>
                        <SuperTitleComponent title='Beyond human capabilities, beyond expectations' />
                        <a
                            href='https://calendly.com/lena_unschooler/interview'
                            target='_blank'
                            rel='noreferrer'
                            className='link-btn_primary ant-btn ant-btn-primary ant-btn-lg'
                        >
                            <span>Book the demo</span>
                        </a>
                    </div>
                    <div className='quote'>
                        <SuperTextComponent description="We enable educational institutions to deliver greater value for students beyond human capabilities. Realtime AI engagement changes courses toward students real goals, interests and pains." />
                    </div>
                </div>

                <div className='wrapper_content-section'>
                    <p >Join 70,000+ students, 400+ institutions from 40+ countries</p>

                    {/* <div className='cards wrapper_outer-section'>
                        {LOGOS.map((feature, i) => (
                            <div key={`${feature.title}-${i}`} className='logo_customer'>
                                <LazyLoadImage
                                    src={feature.src}
                                    alt={feature.title}
                                    height={70}
                                />
                            </div>
                        ))}
                    </div> */}
                </div>
            </div>

            {/* how it works */}
            <div className='wrapper_promo-section'>
                <div className='cards grow-cards wrapper_outer-section'>
                    {FEATURES_DESCRIPTION.map((feature, i) => (
                        <div
                            key={`${feature?.title}-${i}`}
                            className='card_30 card_margin'
                        >
                            <FeatureComponent
                                title={feature?.title}
                                src={feature.src}
                                color={feature.color}
                                description={feature.description}
                            />
                        </div>
                    ))}
                </div>
                <Svg name=':icon/logo-full_star' className='stickers' />
                <div className='wrapper_quote'>
                    <div className='quote'>
                        <SuperTitleComponent title={'Try Unschooler for free'} />
                    </div>
                    <div className='quote'>
                        <SuperTextComponent
                            description={'Help your students exceed expectations. Book a 15-minute demo and start a 14-day free trial ↓'}
                        />
                        <Link
                            to='/payments'
                            className='wrapper_content-section link-btn_primary ant-btn ant-btn-primary ant-btn-lg  '
                        >
                            <span>Start for free</span>
                        </Link>
                    </div>
                </div>
            </div>

            <div className='page'>
                <FooterContainer />
            </div>

            <LoginContainer
                showLogin={showLogin}
                setCancelLogin={setShowLogin}
            />
        </div>
    );
};
