import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SkillsResultComponent } from '../SkillsResult.component';
import { useLocalePrefix } from '../../lang';
import { useGetEvent } from '../../event/hooks/useGetEvent';

const VIDEOS_PACKAGE = 50;
const MAX_ITERATIONS = 20;
const K = 0.8;

export const SkillsResultContainer = (props) => {
    const {
        as: Component = SkillsResultComponent,
        totalVideo,
        iterations,
        matchedVideo,
        processedVideos,
        ...other
    } = props;

    const history = useHistory();
    const { getEvent } = useGetEvent();
    const [localePrefix] = useLocalePrefix();
    const [event, setEvent] = useState();

    const allComparedVideo = useMemo(() => {
        let canProceed = false;

        if (processedVideos !== totalVideo && iterations !== MAX_ITERATIONS) {
            const allPossibleIterations = Math.round(totalVideo / VIDEOS_PACKAGE);
            if ((allPossibleIterations - iterations) > 2) {
                canProceed = true;
            }
        }

        return { counter: processedVideos, canProceed };
    }, [totalVideo, processedVideos, iterations]);

    let accuracy = Math.round((matchedVideo || 0) * K);
    accuracy = accuracy <= 80 ? accuracy : 80;

    const handleCheckout = () => {
        history.push(`${localePrefix}/checkout/`, {
            event: event,
            eventId: event.id,
            isTestSkills: true
        });
    };

    useEffect(() => {
        (async () => {
            const eventData = await getEvent({
                variables: {
                    id: 343
                }
            });
            setEvent(eventData?.data?.event);
        })();
    }, []);

    if (!event) return null;

    return <Component {...other}
        accuracy={accuracy}
        totalVideo={totalVideo}
        matchedVideo={matchedVideo}
        allComparedVideo={allComparedVideo.counter}
        hasNotMathedVideo={allComparedVideo.canProceed}
        price={event?.price?.forStudent}
        onCheckout={handleCheckout} />
}